<template>
  <div class="main-page">
    <h1>Avvicina il tuo green pass alla fotocamera!</h1>
    <div
      class="camera-wrapper"
      :class="validationStatus === 'error' ? 'red-bg' : ''"
    >
      <qr-stream
        v-show="!ongoingValidation"
        :camera="camera"
        @decode="onDecode"
        @init="onInit"
      >
      </qr-stream>

      <div
        v-show="ongoingValidation"
        class="results"
        :class="validationStatus === 'error' ? 'red-bg' : ''"
      >
        <template v-if="validationStatus === 'error'">
          <h3>Green pass non valido, riprova.</h3>
        </template>
        <template v-else-if="validationStatus === 'success'">
          <h3>Il tuo Green Pass è valido!</h3>

          <span><strong>Nome: </strong>{{ passData.nam.gn }}</span>
          <span><strong>Cognome: </strong>{{ passData.nam.fn }}</span>
          <span><strong>Data di nascita: </strong>{{ passData.dob }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { QrStream, QrCapture, QrDropzone } from "vue3-qr-reader";
import base45 from "base45";
import cbor from "cbor";
import pako from "pako";

export default {
  name: "App",
  components: {
    QrStream,
    QrCapture,
    QrDropzone,
  },
  data() {
    return {
      ongoingValidation: false,
      camera: "front",
      passData: null,
      validationStatus: null,
    };
  },
  methods: {
    async validateGP(qrCode) {
      try {
        const greenBody = qrCode.substr(4);
        const decodedData = base45.decode(greenBody);
        const inflatedData = pako.inflate(decodedData);
        const results = cbor.decodeAllSync(inflatedData);
        let [headers1, headers2, cbor_data, signature] = results[0].value;

        const greenPassData = cbor.decodeAllSync(cbor_data);
        const endResult = greenPassData[0].get(-260).get(1);

        console.log(endResult);

        if (!endResult.dob || !endResult.nam) {
          throw "Not valid name or dob.";
        }

        this.passData = endResult;
        this.validationStatus = "success";
        return true;
      } catch (err) {
        console.log(err);
        this.validationStatus = "error";
        return false;
      }
    },

    turnCameraOn() {
      this.camera = "front";
    },

    turnCameraOff() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();

      await this.timeout(500);
      this.isValid = await this.validateGP(content.trim());
      this.ongoingValidation = true;

      window.navigator.vibrate(300);
      await this.timeout(5000);

      this.turnCameraOn();

      this.ongoingValidation = false;
      this.validationStatus = null;
      this.passData = null;

      this.isValid = undefined;
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
  },
};
</script>

<style lang="scss">
* {
  list-style: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
}
body {
  background-color: #f4f4f4;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 40px;
}

.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}

.results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 22px;
  font-weight: bold;

  flex: 1;
  padding: 5px;
}

.camera-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 35vw;
  min-width: 600px;
  background-color: lightgreen;
  border-radius: 15px;
  padding: 5px;
}

.results span:first-of-type {
  margin-top: 20px;
}

.results h3,
.results span {
  display: block;
  margin: 3px auto;
}

.camera-wrapper > * {
  border-radius: 15px;
  overflow: hidden;
}

.red-bg {
  background-color: red;
}

.red-bg h3 {
  color: #fff;
}
</style>
